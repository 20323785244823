import React, { useEffect, useState } from 'react';
import CampaignIcon from '@mui/icons-material/Campaign';
import { MotionComponent } from '../../atom/MotionComponent';
import BrandConfigStore from '@/context/store/brand-config';
import localStorageUtils from '../../../../utils/localStorage.utils';

const AnnouncementComponent = ({ slideMode }) => {
	const { config: brandConfig, selectedLanguage } = BrandConfigStore((state) => ({
		config: state.config,
		selectedLanguage: state.selectedLanguage
	}));

	const [announcement, setAnnouncement] = useState('');

	useEffect(() => {
		// Safe check for server-side rendering
		if (typeof window === 'undefined') return;

		const lang = localStorageUtils.get(lang) || 'en-US'; // Replace 'defaultLangCode' with your default language code
		const announcementConfig = brandConfig?.announcement?.find(
			(ann) => ann?.langCode === lang && ann?.isDisplay
		);

		if (announcementConfig?.content && announcementConfig?.content !== '') {
			setAnnouncement(announcementConfig?.content);
		}
	}, [selectedLanguage]);

	if (!announcement) {
		return null;
	}

	return (
		<React.Fragment>
			<MotionComponent slideMode={slideMode}>
				<div className="flex items-center space-x-2 xl:-mx-2 leading-none mb-section">
					<CampaignIcon className="text-container-color-primary" />
					<div className="w-full overflow-hidden">
						<p className="whitespace-nowrap animate-marquee text-container-color-primary">
							{announcement}
						</p>
					</div>
				</div>
			</MotionComponent>
		</React.Fragment>
	);
};

export default AnnouncementComponent;
